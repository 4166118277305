// import api from './api';
import { functions } from '@/config/firebase.config';

export const fetchReleases = (mtw_id) => {
  return functions
    .httpsCallable('fetchReleasesPerSong')({
      mtw_id,
    })
    .then((res) => {
      return res.data.data;
    });
};

export const fetchWorkTitle = (mtw_id) =>
  functions
    .httpsCallable('fetchWorksTitles')({
      mtw_id,
    })
    .then((res) => {
      return res.data.data;
    });

export const fetchWriters = (mtw_id) =>
  // api.get('writers_per_song', {
  //   params: {
  //     mtw_id,
  //   },
  // });
  functions
    .httpsCallable('fetchWritersPerSong')({
      mtw_id,
    })
    .then((res) => {
      return res.data.data;
    });

export const fetchPublishers = (mtw_id) =>
  // api.get('publishers_per_song', {
  //   params: {
  //     mtw_id,
  //   },
  // });
  functions
    .httpsCallable('fetchPublishersPerSong')({
      mtw_id,
    })
    .then((res) => {
      return res.data.data;
    });

export const fetchSamples = (mtw_id) =>
  // api.get('samples_per_song', {
  //   params: {
  //     mtw_id,
  //   },
  // });
  functions
    .httpsCallable('fetchSamplesPerSong')({
      mtw_id,
    })
    .then((res) => {
      return res.data.data;
    });

export const fetchSyncs = (mtw_id) =>
  // api.get('syncs_per_song', {
  //   params: {
  //     mtw_id,
  //   },
  // });
  functions
    .httpsCallable('fetchSyncsPerSong')({
      mtw_id,
    })
    .then((res) => {
      return res.data.data;
    });

export const fetchSplits = (mtw_id) =>
  // api.get('splits_per_song', {
  //   params: {
  //     mtw_id,
  //   },
  // });
  functions
    .httpsCallable('fetchSplitsPerSong')({
      mtw_id,
    })
    .then((res) => {
      return res.data.data;
    });

export const fetchCodes = (mtw_id) =>
  // api.get('codes_per_song', {
  //   params: {
  //     mtw_id,
  //   },
  // });
  functions
    .httpsCallable('fetchCodesPerSong')({
      mtw_id,
    })
    .then((res) => {
      return res.data.data;
    });

export const fetchTracklist = (apple_collection_id) =>
  // api.get('songs_per_collection', {
  //   params: {
  //     apple_collection_id,
  //   },
  // });
  functions
    .httpsCallable('fetchSongsPerCollection')({
      apple_collection_id,
    })
    .then((res) => {
      return res.data.data;
    });

export const searchCoveredTracks = ({ query, filters }) =>
  functions
    .httpsCallable('searchCoveredTracks')({
      query,
      filters,
    })
    .then((res) => {
      return res.data.data;
    });
