import { render, staticRenderFns } from "./Syncs.vue?vue&type=template&id=5fa14475&scoped=true&"
import script from "./Syncs.vue?vue&type=script&lang=js&"
export * from "./Syncs.vue?vue&type=script&lang=js&"
import style0 from "./Syncs.vue?vue&type=style&index=0&id=5fa14475&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5fa14475",
  null
  
)

export default component.exports